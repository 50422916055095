@import url(https://fonts.googleapis.com/css?family=Cairo);
body {
  margin: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Bylynn Sans', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wallect-connect-login_cardBody #accessWalletBtn {
  background: #000;
  color: #fff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
}
.wallect-connect-login_cardBody #accessWalletBtn svg {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  margin: 0 10px 0 0 !important;
}
.wallect-connect-login_cardBody #accessWalletBtn svg image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.wallect-connect-login_cardBody #accessWalletBtn svg use {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.wallect-connect-login_cardBody #accessWalletBtn {
  background: #000;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
}
.wallect-connect-login_cardBody #accessWalletBtn svg {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  margin: 0 10px 0 0 !important;
}
.wallect-connect-login_cardBody #accessWalletBtn svg image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.wallect-connect-login_cardBody #accessWalletBtn svg use {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.wallect-connect-login_loginText {
  font-size: 14px;
}

.bg_connect_maiar_btn {
  margin-top: 30px;
}
.bg_connect_maiar_btn button {
  font-size: 14px;
  color: #000000 !important;
  display: block !important;
  position: relative;
  padding-left: 65px !important;
  max-width: 250px;
  width: auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 auto !important ;
}
.bg_connect_maiar_btn button::before {
  content: "";
  position: absolute;
  left: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 21px;
  height: 20px;
  background-image: url(../../static/media/maiar.4afbafd7.png);
  background-size: 21px 20px;
}
* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-color: #eeeeee;
}

.Row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Row-letter {
  margin: 2px;
  border: 2px solid rgba(238, 238, 238, 0.9);
  flex: 1 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}
.Row-letter-color {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.Row-annotation {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  width: 5em;
  text-align: start;
}

.App-container {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  height: 100%;
}

.App-container h1 {
  margin-top: 0;
}

.Game,
h1 {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Game {
  display: flex;
  flex-direction: column;
}

table.Game-rows {
  margin: auto;
  display: flex;
  flex-direction: column;
}

table.Game-rows:focus {
  outline: none;
}

table.Game-rows > tbody {
  display: flex;
  flex-direction: column;
}

.Game-keyboard {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: #cdcdcd;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
}

.Game-keyboard-button-wide {
  flex: 2 1;
}

.Game-keyboard-button:focus {
  outline: none;
}

.letter-correct {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(87, 172, 120);
  color: white !important;
}

.letter-elsewhere {
  border: 2px dotted rgba(0, 0, 0, 0.3);
  background-color: #e9c601;
  color: white !important;
}

.letter-absent {
  border: 2px solid transparent;
  background-color: rgb(162, 162, 162);
  color: white !important;
}

body.dark {
  background-color: #404040;
  color: #e0e0e0;
  color-scheme: dark;
}

body.dark .Game-keyboard-button {
  color: #404040;
}

a,
a:visited {
  color: #8080ff;
}

a:active {
  color: #cc77ff;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Game-options > * + * {
  -webkit-margin-start: 0.5rem;
          margin-inline-start: 0.5rem;
}

.Game-options button {
  min-width: 4rem;
}

.App-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.App-about {
  margin-top: -1rem;
  line-height: 1.4;
}

.App-about b {
  background-color: #888;
  color: white;
  padding: 1px 3px;
  border-radius: 2px;
}

.App-about b.green-bg {
  background-color: rgb(87, 172, 120);
}

.App-about b.yellow-bg {
  background-color: #e9c601;
}

.Game-seed-info {
  opacity: 0.7;
  margin-top: 1em;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.Game-sr-feedback,
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.Settings {
  text-align: left;
  font-size: 18px;
}

.Settings-setting {
  margin: 8px;
  display: flex;
  align-items: top;
}

.Settings-setting input {
  width: 18px;
  height: 18px;
}

.Settings-setting input[type="range"] {
  width: 50px;
  height: 18px;
}

.Settings-setting label {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}

.top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.emoji-link {
  font-size: 24px;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.top-right > * + * {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
}

.App-container.color-blind .letter-correct,
.App-container.color-blind .App-about b.green-bg {
  background-color: #f5793a;
}

.App-container.color-blind .letter-elsewhere,
.App-container.color-blind .App-about b.yellow-bg {
  background-color: #85c0f9;
}

.Prize {
  width: 50;
  height: 50;
  object-fit: cover;
}

.carousel .slider-wrapper.axis-vertical,
.carousel .slider-wrapper.axis-vertical .slider,
.carousel
  .slider-wrapper.axis-vertical
  .slider
  li
  div[class*="makeStyles-gameSliderImageContent"],
.carousel .slider-wrapper.axis-vertical .slider li div[style*="height"] {
  height: 90vh !important;
}

* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-color: #eeeeee;
}

.Row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Row-letter {
  margin: 2px;
  border: 2px solid rgba(238, 238, 238, 0.9);
  flex: 1 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}
.Row-letter-color {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.Row-annotation {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  width: 5em;
  text-align: start;
}

.Word-container {
  position: relative;
  max-width: 480px;
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: flex-start;
  height: 100vh;
}

.Word-container h1 {
  margin-top: 0;
}

.Game,
h1 {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Game {
  display: flex;
  flex-direction: column;
}

table.Game-rows {
  margin: auto;
  display: flex;
  flex-direction: column;
}

table.Game-rows:focus {
  outline: none;
}

table.Game-rows > tbody {
  display: flex;
  flex-direction: column;
}

.Game-keyboard {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: #cdcdcd;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
}

.Game-keyboard-button-wide {
  flex: 2 1;
}

.Game-keyboard-button:focus {
  outline: none;
}

.letter-correct {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(87, 172, 120);
  color: white !important;
}

.letter-elsewhere {
  border: 2px dotted rgba(0, 0, 0, 0.3);
  background-color: #e9c601;
  color: white !important;
}

.letter-absent {
  border: 2px solid transparent;
  background-color: rgb(162, 162, 162);
  color: white !important;
}

body.dark {
  background-color: #404040;
  color: #e0e0e0;
  color-scheme: dark;
}

body.dark .Game-keyboard-button {
  color: #404040;
}

a,
a:visited {
  color: #8080ff;
}

a:active {
  color: #cc77ff;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Game-options > * + * {
  -webkit-margin-start: 0.5rem;
          margin-inline-start: 0.5rem;
}

.Game-options button {
  min-width: 4rem;
}

.Word-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.Word-about {
  margin-top: -1rem;
  line-height: 1.4;
}

.Word-about b {
  background-color: #888;
  color: white;
  padding: 1px 3px;
  border-radius: 2px;
}

.Word-about b.green-bg {
  background-color: rgb(87, 172, 120);
}

.Word-about b.yellow-bg {
  background-color: #e9c601;
}

.Game-seed-info {
  opacity: 0.7;
  margin-top: 1em;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.Game-sr-feedback,
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.Settings {
  text-align: left;
  font-size: 18px;
}

.Settings-setting {
  margin: 8px;
  display: flex;
  align-items: top;
}

.Settings-setting input {
  width: 18px;
  height: 18px;
}

.Settings-setting input[type="range"] {
  width: 50px;
  height: 18px;
}

.Settings-setting label {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}

.top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.emoji-link {
  font-size: 24px;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.top-right > * + * {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
}

.Word-container.color-blind .letter-correct,
.Word-container.color-blind .Word-about b.green-bg {
  background-color: #f5793a;
}

.Word-container.color-blind .letter-elsewhere,
.Word-container.color-blind .Word-about b.yellow-bg {
  background-color: #85c0f9;
}

.Prize {
  width: 50;
  height: 50;
  object-fit: cover;
}

.Title {
  text-align: center;
  color: white;
  margin-bottom: 20px;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
}
* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-color: #eeeeee;
}

.Row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Row-letter {
  margin: 2px;
  border: 2px solid rgba(238, 238, 238, 0.9);
  flex: 1 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #b0b0b0;
}
.Row-letter-color {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.Row-annotation {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  width: 5em;
  text-align: start;
}

.App-container {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  height: 100%;
}

.App-container h1 {
  margin-top: 0;
}

.Game,
h1 {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Game {
  display: flex;
  flex-direction: column;
}

table.Game-rows {
  margin: auto;
  display: flex;
  flex-direction: column;
}

table.Game-rows:focus {
  outline: none;
}

table.Game-rows > tbody {
  display: flex;
  flex-direction: column;
}

.Game-keyboard {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: #cdcdcd;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
}

.Game-keyboard-button-wide {
  flex: 2 1;
}

.Game-keyboard-button:focus {
  outline: none;
}

.letter-correct {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(87, 172, 120);
  color: white !important;
}

.letter-elsewhere {
  border: 2px dotted rgba(0, 0, 0, 0.3);
  background-color: #e9c601;
  color: white !important;
}

.letter-absent {
  border: 2px solid transparent;
  background-color: rgb(162, 162, 162);
  color: white !important;
}

body.dark {
  background-color: #404040;
  color: #e0e0e0;
  color-scheme: dark;
}

body.dark .Game-keyboard-button {
  color: #404040;
}

a,
a:visited {
  color: #8080ff;
}

a:active {
  color: #cc77ff;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Game-options > * + * {
  -webkit-margin-start: 0.5rem;
          margin-inline-start: 0.5rem;
}

.Game-options button {
  min-width: 4rem;
}

.App-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.App-about {
  margin-top: -1rem;
  line-height: 1.4;
}

.App-about b {
  background-color: #888;
  color: white;
  padding: 1px 3px;
  border-radius: 2px;
}

.App-about b.green-bg {
  background-color: rgb(87, 172, 120);
}

.App-about b.yellow-bg {
  background-color: #e9c601;
}

.Game-seed-info {
  opacity: 0.7;
  margin-top: 1em;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.Game-sr-feedback,
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.Settings {
  text-align: left;
  font-size: 18px;
}

.Settings-setting {
  margin: 8px;
  display: flex;
  align-items: top;
}

.Settings-setting input {
  width: 18px;
  height: 18px;
}

.Settings-setting input[type="range"] {
  width: 50px;
  height: 18px;
}

.Settings-setting label {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}

.top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.emoji-link {
  font-size: 24px;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.top-right > * + * {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
}

.App-container.color-blind .letter-correct,
.App-container.color-blind .App-about b.green-bg {
  background-color: #f5793a;
}

.App-container.color-blind .letter-elsewhere,
.App-container.color-blind .App-about b.yellow-bg {
  background-color: #85c0f9;
}

.Prize {
  width: 50;
  height: 50;
  object-fit: cover;
}
.tutorialImage {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
}

.close {
  position: relative;
  top: -40%;
  right: 20px;
  color: white;
  font-size: 16;
}

.logo {
  border: 2px solid #379ea3;
  border-radius: 100px;
  width: 70px;
  height: 70px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px auto 0;
  padding: 10px;
}
.container {
  max-width: 480px !important;
  margin: 0 auto;
  padding: 0 15px !important;
  display: flex;
  justify-content: center;
}
.Topic-container {
  position: relative;
  max-width: 480px;
  height: 100%;
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
}
.Letterz {
  border-radius: 10%;
  margin: 3px;
  height: 30px;
  width: 25px;
  text-align: center;
  list-style-type: none;
  display: inline-block;
  color: white;
  font-size: small;
  /* background-color: red; */

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5),
    /* Exterior Shadow */ inset 0 1px rgba(255, 255, 255, 0.3),
    /* Top light Line */ inset 0 10px rgba(255, 255, 255, 0.2),
    /* Top Light Shadow */ inset 0 10px 20px rgba(255, 255, 255, 0.25),
    /* Sides Light Shadow */ inset 0 -15px 30px rgba(0, 0, 0, 0.1); /* Dark Background */
}

.Letterz button,
.Letterz span {
  color: white;
  background: transparent;
  border: none;
}

.Letterz span {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Letter.disabled {
  box-shadow: none;
}

/* .dash {
  border: none !important;
} */

.space {
  border: none !important;
  display: block;
  background-color: transparent;
  height: 0;
  padding: 0;
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .Letterz {
    margin: 1vw;
    width: 8vw;
    min-width: 25px;
    font-size: 13px;
  }
}

@media screen and (max-width: 420px) {
  .Letterz {
    margin: 1vw;
    width: 8vw;
    min-width: 25px;
    font-size: 13px;
  }
}
.Alphabet .Letterz {
  cursor: pointer;
  font-weight: 600;
  background-color: red;
  padding-top: 4px;  
  font-size: 13px;
}

.Alphabet .Letterz button {
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
}

@media screen and (min-width: 1200px) {
  .Alphabet .Letterz:hover {
    transition: all 0.2s;
    -webkit-transform: rotate(-3deg) scale(1.1);
            transform: rotate(-3deg) scale(1.1);
  }
}

.Alphabet .Letterz.disabled {
  background-color: transparent;
  cursor: not-allowed;
  -webkit-transform: none;
          transform: none;
}

.Alphabet button[disabled] {
  cursor: not-allowed;
}

.Alphabet {
  background-color: orange;
  padding-top: 15px;
  padding-bottom: 20px;
  padding-right: 6%;
  padding-left: 6%;
  justify-content: center;
  align-items: center;
  display: flexbox;
  
}

@media screen and (max-width: 600px) {
  .Alphabet .Letterz {
    padding-right: 28px;
    font-size: 13px;
  }

  .Alphabet {
    padding-right: 0vw;
    padding-left: 1vw;
  }
}

@media screen and (max-width: 420px) {
  .Alphabet .Letterz {
    /* padding-right: 30px; */
    padding-right: 35px;
    padding-top: 4px;  
  }

  .Alphabet {
    padding-right: 0vw;
    padding-left: 1vw;
  }
}
.CategoryContainer {
  border: 5px solid white;
  width: 80%;
  border-radius: 12px;
  height: 150px;
  position: relative;
  left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.CategoryImage {
  width: 100%;
  height: 146px;
  object-fit: stretch;
  position: absolute;
  margin-top: 0px;
  border-radius: 12px;
  z-index: 1;
}

.Category {
  /* text-transform: uppercase; */
  color: white;
  font-size: large;
  text-align: center;
  z-index: 1;
  padding: 5px;
}

@media only screen and (max-width: 420px) {
  .Category {
    font-size: medium;
    font-weight: 400;
    padding: 5px;
  }
  .CategoryImage {
    left: 0%;
    width: 100%;
  }
}

.GuessesLeft {
  text-align: center;
  display: inline-flex;
  color: white;
  cursor: default;
  padding: 1rem;
  font-size: 20px;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
}

.GuessContainer {
  width: 100%;
  min-width: 480px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .GuessesLeft {
    width: 100%;
    min-width: 360px;
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin-left: 6%;
  }
  .GuessContainer {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.Word .Letterz button,
.Word .Letterz {
  color: white;
  background-color: #db3eb1;
  border: solid #db3eb1 2px;
  border-radius: 4;
  cursor: default;
  box-shadow: none;
  font-weight: 600;
  width: 20px;
  margin-left: -1px;
  margin-bottom: 10px;
}

.Word .Letterz button {
  border: none;
}

.Word .Letterz.disabled button,
.Word .Letter.dash {
  color: white;
}

.word-container {
  padding-top: 25px;
  padding-bottom: 20px;
  justify-content: center;
  display: flex;
  padding-right: 3%;
  padding-left: 0%;
}

@media screen and (max-width: 420px) {
  .word-container {
    padding-top: 25px;
    padding-bottom: 20px;
    justify-content: center;
    display: flex;
    padding-right: 9%;
    padding-left: 0%;
  }
}
/* * {
  margin: 0;
  padding: 0;
  font-size: xx-large;
}


body {
  padding: 0.5rem;
  font-size: larger;
}

@media only screen and (max-width: 600px) {
  body {
    padding: 0.1rem;
  }
}

footer .fa {
  color: white;
  float: left;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.footer {
  display: inline-block;
  border-radius: 10px;
  margin-top: 1em;
}

@media only screen and (max-width: 600px) {
  footer {
    margin: auto;
    width: 50%;
  }
}
*/

.footer .fa {
  color: white;
  float: left;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.footer {
  display: inline-block;
  border-radius: 10px;
  margin-top: 1em;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
} 

.topic-main-bg {
  background-color: orange;
  width: 100%;
  max-width: 480px;
  height: 95vh;  
  padding-top: 20px;
  overflow: hidden,
}

.topic-main-image {
  width: 100%;
  height: 95vh; 
  max-width: 480px; 
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.tutorialImage {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
}

.close {
  position: relative;
  top: -40%;
  right: 20px;
  color: white;
  font-size: 16;
}
* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-color: #eeeeee;
}

.Row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Row-letter {
  margin: 2px;
  border: 2px solid rgba(238, 238, 238, 0.9);
  flex: 1 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}
.Row-letter-color {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.Row-annotation {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  width: 5em;
  text-align: start;
}

.Number-container {
  position: relative;
  max-width: 480px;
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: flex-start;
  height: 88vh;
  overscroll-behavior-y: none;
  overflow: hidden;
}

.Number-container h1 {
  margin-top: 0;
}

.Game,
h1 {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Game {
  display: flex;
  flex-direction: column;
}

table.Game-rows {
  margin: auto;
  display: flex;
  flex-direction: column;
}

table.Game-rows:focus {
  outline: none;
}

table.Game-rows > tbody {
  display: flex;
  flex-direction: column;
}

.Game-keyboard {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: #cdcdcd;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
}

.Game-keyboard-button-wide {
  flex: 2 1;
}

.Game-keyboard-button:focus {
  outline: none;
}

.letter-correct {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(87, 172, 120);
  color: white !important;
}

.letter-elsewhere {
  border: 2px dotted rgba(0, 0, 0, 0.3);
  background-color: #e9c601;
  color: white !important;
}

.letter-absent {
  border: 2px solid transparent;
  background-color: rgb(162, 162, 162);
  color: white !important;
}

body.dark {
  background-color: #404040;
  color: #e0e0e0;
  color-scheme: dark;
}

body.dark .Game-keyboard-button {
  color: #404040;
}

a,
a:visited {
  color: #8080ff;
}

a:active {
  color: #cc77ff;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Game-options > * + * {
  -webkit-margin-start: 0.5rem;
          margin-inline-start: 0.5rem;
}

.Game-options button {
  min-width: 4rem;
}

.Word-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.Word-about {
  margin-top: -1rem;
  line-height: 1.4;
}

.Word-about b {
  background-color: #888;
  color: white;
  padding: 1px 3px;
  border-radius: 2px;
}

.Word-about b.green-bg {
  background-color: rgb(87, 172, 120);
}

.Word-about b.yellow-bg {
  background-color: #e9c601;
}

.Game-seed-info {
  opacity: 0.7;
  margin-top: 1em;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.Game-sr-feedback,
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.Settings {
  text-align: left;
  font-size: 18px;
}

.Settings-setting {
  margin: 8px;
  display: flex;
  align-items: top;
}

.Settings-setting input {
  width: 18px;
  height: 18px;
}

.Settings-setting input[type="range"] {
  width: 50px;
  height: 18px;
}

.Settings-setting label {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}

.top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.emoji-link {
  font-size: 24px;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.top-right > * + * {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
}

.Number-container.color-blind .letter-correct,
.Number-container.color-blind .Word-about b.green-bg {
  background-color: #f5793a;
}

.Number-container.color-blind .letter-elsewhere,
.Number-container.color-blind .Word-about b.yellow-bg {
  background-color: #85c0f9;
}

.Prize {
  width: 50;
  height: 50;
  object-fit: cover;
}

.Title {
  text-align: center;
  color: white;
  margin-bottom: 20px;
}
.numberbody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: "Arial", sans-serif;
  font-size: 21px;
  height: 60vh;
}
.text {
  order: 2;
  padding-top: 40px;
  width: 330px;
  font-weight: bold;
}
.board {
  order: 1;
  width: 330px;
  height: 330px;
  padding: 5px;
  background-color: #baa;
  border-radius: 7px;
  outline: none;
  position: relative;
}
.board .cell,
.tile {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
}
.cell,
.tile {
  width: 70px;
  height: 70px;
  margin: 5px;
  line-height: 70px;
  display: inline-block;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-radius: 7px;
  font-family: "Arial";
  color: #766;
  background-color: #dcb;
}
.tile0 {
  background-color: #dcb;
}
.tile2 {
  background-color: #eee;
}
.tile4 {
  background-color: #eec;
}
.tile8 {
  color: #ffe;
  background-color: #fb8;
}
.tile16 {
  color: #ffe;
  background-color: #f96;
}
.tile32 {
  color: #ffe;
  background-color: #f75;
}
.tile64 {
  color: #ffe;
  background-color: #f53;
}
.tile128 {
  color: #ffe;
  background-color: #ec7;
  font-size: 30px;
}
.tile256 {
  color: #ffe;
  background-color: #ec6;
  font-size: 30px;
}
.tile512 {
  color: #ffe;
  background-color: #ec5;
  font-size: 30px;
}
.tile1024 {
  color: #fff;
  background-color: #ec3;
  font-size: 25px;
}
.tile2048 {
  color: #fff;
  background-color: #ec2;
  font-size: 25px;
}
.tile {
  position: absolute;
}
.tile.merged {
  display: none;
}
.tile.merged.isMoving {
  display: inline;
}
.tile.new,
.overlay {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: newTile;
          animation-name: newTile;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
  -webkit-transform: scale(0);
          transform: scale(0);
}
@-webkit-keyframes newTile {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes newTile {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 0px;
  right: 0px;
  font-size: 55px;
  font-weight: bolder;
  background-color: rgba(221, 221, 221, 0.5);
  border-radius: 7px;
}
.tryAgain {
  background-color: #876;
  color: #fff;
  height: 40px;
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}
.overlay .message {
  color: #666;
}

.position_0_0:not(.isMoving) {
  top: 5px;
  left: 5px;
}
.position_0_1:not(.isMoving) {
  top: 5px;
  left: 85px;
}
.position_0_2:not(.isMoving) {
  top: 5px;
  left: 165px;
}
.position_0_3:not(.isMoving) {
  top: 5px;
  left: 245px;
}
.position_1_0:not(.isMoving) {
  top: 85px;
  left: 5px;
}
.position_1_1:not(.isMoving) {
  top: 85px;
  left: 85px;
}
.position_1_2:not(.isMoving) {
  top: 85px;
  left: 165px;
}
.position_1_3:not(.isMoving) {
  top: 85px;
  left: 245px;
}
.position_2_0:not(.isMoving) {
  top: 165px;
  left: 5px;
}
.position_2_1:not(.isMoving) {
  top: 165px;
  left: 85px;
}
.position_2_2:not(.isMoving) {
  top: 165px;
  left: 165px;
}
.position_2_3:not(.isMoving) {
  top: 165px;
  left: 245px;
}
.position_3_0:not(.isMoving) {
  top: 245px;
  left: 5px;
}
.position_3_1:not(.isMoving) {
  top: 245px;
  left: 85px;
}
.position_3_2:not(.isMoving) {
  top: 245px;
  left: 165px;
}
.position_3_3:not(.isMoving) {
  top: 245px;
  left: 245px;
}
.row_from_0_to_0 {
  top: 5px;
}
.row_from_0_to_1 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_0_to_1;
          animation-name: row_from_0_to_1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_0_to_1 {
  from {
    top: 5px;
  }
  to {
    top: 85px;
  }
}
@keyframes row_from_0_to_1 {
  from {
    top: 5px;
  }
  to {
    top: 85px;
  }
}
.row_from_0_to_2 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_0_to_2;
          animation-name: row_from_0_to_2;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_0_to_2 {
  from {
    top: 5px;
  }
  to {
    top: 165px;
  }
}
@keyframes row_from_0_to_2 {
  from {
    top: 5px;
  }
  to {
    top: 165px;
  }
}
.row_from_0_to_3 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_0_to_3;
          animation-name: row_from_0_to_3;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_0_to_3 {
  from {
    top: 5px;
  }
  to {
    top: 245px;
  }
}
@keyframes row_from_0_to_3 {
  from {
    top: 5px;
  }
  to {
    top: 245px;
  }
}
.row_from_1_to_0 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_1_to_0;
          animation-name: row_from_1_to_0;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_1_to_0 {
  from {
    top: 85px;
  }
  to {
    top: 5px;
  }
}
@keyframes row_from_1_to_0 {
  from {
    top: 85px;
  }
  to {
    top: 5px;
  }
}
.row_from_1_to_1 {
  top: 85px;
}
.row_from_1_to_2 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_1_to_2;
          animation-name: row_from_1_to_2;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_1_to_2 {
  from {
    top: 85px;
  }
  to {
    top: 165px;
  }
}
@keyframes row_from_1_to_2 {
  from {
    top: 85px;
  }
  to {
    top: 165px;
  }
}
.row_from_1_to_3 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_1_to_3;
          animation-name: row_from_1_to_3;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_1_to_3 {
  from {
    top: 85px;
  }
  to {
    top: 245px;
  }
}
@keyframes row_from_1_to_3 {
  from {
    top: 85px;
  }
  to {
    top: 245px;
  }
}
.row_from_2_to_0 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_2_to_0;
          animation-name: row_from_2_to_0;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_2_to_0 {
  from {
    top: 165px;
  }
  to {
    top: 5px;
  }
}
@keyframes row_from_2_to_0 {
  from {
    top: 165px;
  }
  to {
    top: 5px;
  }
}
.row_from_2_to_1 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_2_to_1;
          animation-name: row_from_2_to_1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_2_to_1 {
  from {
    top: 165px;
  }
  to {
    top: 85px;
  }
}
@keyframes row_from_2_to_1 {
  from {
    top: 165px;
  }
  to {
    top: 85px;
  }
}
.row_from_2_to_2 {
  top: 165px;
}
.row_from_2_to_3 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_2_to_3;
          animation-name: row_from_2_to_3;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_2_to_3 {
  from {
    top: 165px;
  }
  to {
    top: 245px;
  }
}
@keyframes row_from_2_to_3 {
  from {
    top: 165px;
  }
  to {
    top: 245px;
  }
}
.row_from_3_to_0 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_3_to_0;
          animation-name: row_from_3_to_0;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_3_to_0 {
  from {
    top: 245px;
  }
  to {
    top: 5px;
  }
}
@keyframes row_from_3_to_0 {
  from {
    top: 245px;
  }
  to {
    top: 5px;
  }
}
.row_from_3_to_1 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_3_to_1;
          animation-name: row_from_3_to_1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_3_to_1 {
  from {
    top: 245px;
  }
  to {
    top: 85px;
  }
}
@keyframes row_from_3_to_1 {
  from {
    top: 245px;
  }
  to {
    top: 85px;
  }
}
.row_from_3_to_2 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: row_from_3_to_2;
          animation-name: row_from_3_to_2;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes row_from_3_to_2 {
  from {
    top: 245px;
  }
  to {
    top: 165px;
  }
}
@keyframes row_from_3_to_2 {
  from {
    top: 245px;
  }
  to {
    top: 165px;
  }
}
.row_from_3_to_3 {
  top: 245px;
}
.column_from_0_to_0 {
  left: 5px;
}
.column_from_0_to_1 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_0_to_1;
          animation-name: column_from_0_to_1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_0_to_1 {
  from {
    left: 5px;
  }
  to {
    left: 85px;
  }
}
@keyframes column_from_0_to_1 {
  from {
    left: 5px;
  }
  to {
    left: 85px;
  }
}
.column_from_0_to_2 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_0_to_2;
          animation-name: column_from_0_to_2;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_0_to_2 {
  from {
    left: 5px;
  }
  to {
    left: 165px;
  }
}
@keyframes column_from_0_to_2 {
  from {
    left: 5px;
  }
  to {
    left: 165px;
  }
}
.column_from_0_to_3 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_0_to_3;
          animation-name: column_from_0_to_3;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_0_to_3 {
  from {
    left: 5px;
  }
  to {
    left: 245px;
  }
}
@keyframes column_from_0_to_3 {
  from {
    left: 5px;
  }
  to {
    left: 245px;
  }
}
.column_from_1_to_0 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_1_to_0;
          animation-name: column_from_1_to_0;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_1_to_0 {
  from {
    left: 85px;
  }
  to {
    left: 5px;
  }
}
@keyframes column_from_1_to_0 {
  from {
    left: 85px;
  }
  to {
    left: 5px;
  }
}
.column_from_1_to_1 {
  left: 85px;
}
.column_from_1_to_2 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_1_to_2;
          animation-name: column_from_1_to_2;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_1_to_2 {
  from {
    left: 85px;
  }
  to {
    left: 165px;
  }
}
@keyframes column_from_1_to_2 {
  from {
    left: 85px;
  }
  to {
    left: 165px;
  }
}
.column_from_1_to_3 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_1_to_3;
          animation-name: column_from_1_to_3;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_1_to_3 {
  from {
    left: 85px;
  }
  to {
    left: 245px;
  }
}
@keyframes column_from_1_to_3 {
  from {
    left: 85px;
  }
  to {
    left: 245px;
  }
}
.column_from_2_to_0 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_2_to_0;
          animation-name: column_from_2_to_0;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_2_to_0 {
  from {
    left: 165px;
  }
  to {
    left: 5px;
  }
}
@keyframes column_from_2_to_0 {
  from {
    left: 165px;
  }
  to {
    left: 5px;
  }
}
.column_from_2_to_1 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_2_to_1;
          animation-name: column_from_2_to_1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_2_to_1 {
  from {
    left: 165px;
  }
  to {
    left: 85px;
  }
}
@keyframes column_from_2_to_1 {
  from {
    left: 165px;
  }
  to {
    left: 85px;
  }
}
.column_from_2_to_2 {
  left: 165px;
}
.column_from_2_to_3 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_2_to_3;
          animation-name: column_from_2_to_3;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_2_to_3 {
  from {
    left: 165px;
  }
  to {
    left: 245px;
  }
}
@keyframes column_from_2_to_3 {
  from {
    left: 165px;
  }
  to {
    left: 245px;
  }
}
.column_from_3_to_0 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_3_to_0;
          animation-name: column_from_3_to_0;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_3_to_0 {
  from {
    left: 245px;
  }
  to {
    left: 5px;
  }
}
@keyframes column_from_3_to_0 {
  from {
    left: 245px;
  }
  to {
    left: 5px;
  }
}
.column_from_3_to_1 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_3_to_1;
          animation-name: column_from_3_to_1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_3_to_1 {
  from {
    left: 245px;
  }
  to {
    left: 85px;
  }
}
@keyframes column_from_3_to_1 {
  from {
    left: 245px;
  }
  to {
    left: 85px;
  }
}
.column_from_3_to_2 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: column_from_3_to_2;
          animation-name: column_from_3_to_2;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes column_from_3_to_2 {
  from {
    left: 245px;
  }
  to {
    left: 165px;
  }
}
@keyframes column_from_3_to_2 {
  from {
    left: 245px;
  }
  to {
    left: 165px;
  }
}
.column_from_3_to_3 {
  left: 245px;
}

.tutorialImage {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
}

.close {
  position: relative;
  top: -40%;
  right: 20px;
  color: white;
  font-size: 16;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
}
footer {
  position:absolute;
  left:0;
  bottom:0;
  right:0;

}

.gameOverText {

}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  margin-top: 80px;
  line-height: 1.5em;
  letter-spacing: .1em;
  font-size: 40px;
  color: #fff;
  text-align: center;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #0e0124;
}
.big{
  text-transform: uppercase;
  font-size: 50px;
  color: rgb(173, 106, 17);
  -webkit-text-decoration: underline wavy rgb(196, 196, 207) 5px;
          text-decoration: underline wavy rgb(196, 196, 207) 5px;

}

.copyRight{
    position: absolute;
    width: 100%;
    color: #fff;
    line-height: 40px;
    font-size: 1rem;
    text-align: center;
    bottom:0;
}

.startScreen {
  position: relative;
  height: 100vh;
  display: flex;
  color: white;
  background-color: #020230;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;

}

.app {
  display: flex;
  justify-content: center;
  /* background-color: #020230; */
  color: white;
}

.main {
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #19191f),
    url("https://wallpaperaccess.com/full/2384073.jpg") center;  */
  display: flex;
  flex-direction: column;
  background-color: orange;
  width: 100%;
  max-width: 480px;
  height: 95vh;  
  padding-top: 20px;
  overflow: hidden,
}

.pyramid {
  background-color: #020230;

}

.moneyList {
  padding: 20px;
  width: 100%;
}

.moneyListItem {
  padding: 5px;
  border-radius: 5px;
  border-style: groove;
  border-color: aliceblue;
  border-width: medium;
}

.moneyListItem.active {
  background-color: teal;
}

.moneyListItemNumber {
  font-size: 18px;
  font-weight: 100;
}

.moneyListItemAmount {
  font-size: 20px;
  font-weight: 300;
}

.top {
  height: 30%;
  position: relative;
  display: flex;
  justify-content: center;
}

.used{
  text-decoration: line-through;
  font-weight: 100;
  color: red;
}

.timer {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* border: 5px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 80px;
  font-size: 30px;
  font-weight: 700;
}

.change {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 180px;
  font-size: 30px;
  font-weight: 700;
}

.double {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 280px;
  font-size: 20px;
  font-weight: 700;
}

.fifty {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 280px;
  font-size: 20px;
  font-weight: 700;
}

.bottom {
  height: 200%;
}

.questionnaire {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.question1 {
  width: 80%;
  height: 150px;
  background: linear-gradient(#100241, black);
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid white;
  font-size: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  top: 40px;
}

.answers {
  margin-bottom: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.answer {
  width: 40%;
  padding: 10px;
  margin: 0 10px 20px 10px;
  text-align: center;
  background: linear-gradient(#0e0124, #22074d);
  border: 1px solid white;
  border-radius: 15px;
  font-weight: 300;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  color: white;
}

/* .answer:hover{
  background: mediumblue;
} */

.answer.correct {
  -webkit-animation: correct 3s ease forwards;
          animation: correct 3s ease forwards;
}

@-webkit-keyframes correct {

  2%,
  12%,
  27% {
    background: mediumblue;
  }

  10%,
  25%,
  40% {
    background: linear-gradient(#0e0124, #22074d);
  }

  42%,
  100% {
    background: green;
  }
}

@keyframes correct {

  2%,
  12%,
  27% {
    background: mediumblue;
  }

  10%,
  25%,
  40% {
    background: linear-gradient(#0e0124, #22074d);
  }

  42%,
  100% {
    background: green;
  }
}

.answer.wrong {
  -webkit-animation: wrong 3s ease forwards;
          animation: wrong 3s ease forwards;
}

@-webkit-keyframes wrong {

  2%,
  12%,
  27% {
    background: mediumblue;
  }

  10%,
  25%,
  40% {
    background: linear-gradient(#0e0124, #22074d);
  }

  42%,
  100% {
    background: crimson;
  }
}

@keyframes wrong {

  2%,
  12%,
  27% {
    background: mediumblue;
  }

  10%,
  25%,
  40% {
    background: linear-gradient(#0e0124, #22074d);
  }

  42%,
  100% {
    background: crimson;
  }
}

.timesup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}


.start {
  width: 250px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.startInput {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  color: black;
}

.startInput:focus {
  outline: none;
}

.startButton {
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
}
footer {
  position:absolute;
  left:0;
  bottom:0;
  right:0;

}

.gameOverText {

}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  margin-top: 80px;
  line-height: 1.5em;
  letter-spacing: .1em;
  font-size: 40px;
  color: #fff;
  text-align: center;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #0e0124;
}
.big{
  text-transform: uppercase;
  font-size: 50px;
  color: rgb(173, 106, 17);
  -webkit-text-decoration: underline wavy rgb(196, 196, 207) 5px;
          text-decoration: underline wavy rgb(196, 196, 207) 5px;

}

.copyRight{
    position: absolute;
    width: 100%;
    color: #fff;
    line-height: 40px;
    font-size: 1rem;
    text-align: center;
    bottom:0;
}

.startScreen {
  position: relative;
  height: 100vh;
  display: flex;
  color: white;
  background-color: #020230;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;

}

.app {
  display: flex;
  justify-content: center;
  /* background-color: #020230; */
  color: white;
}

.main {
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #19191f),
    url("https://wallpaperaccess.com/full/2384073.jpg") center;  */
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  max-width: 480px;
  height: 95vh;  
  padding-top: 20px;
  overflow: hidden,
}

.pyramid {
  background-color: #020230;

}

.moneyList {
  padding: 20px;
  width: 100%;
}

.moneyListItem {
  padding: 5px;
  border-radius: 5px;
  border-style: groove;
  border-color: aliceblue;
  border-width: medium;
}

.moneyListItem.active {
  background-color: teal;
}

.moneyListItemNumber {
  font-size: 18px;
  font-weight: 100;
}

.moneyListItemAmount {
  font-size: 20px;
  font-weight: 300;
}

.top {
  height: 20%;
  position: relative;
  display: flex;
  justify-content: center;
}

.used{
  text-decoration: line-through;
  font-weight: 100;
  color: red;
}

.phtimer {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* border: 5px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  font-size: 30px;
  font-weight: 700;
}

.change {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 180px;
  font-size: 30px;
  font-weight: 700;
}

.double {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 280px;
  font-size: 20px;
  font-weight: 700;
}

.fifty {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 280px;
  font-size: 20px;
  font-weight: 700;
}

.bottom {
  height: 200%;
}

.questionnaire {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.question1 {
  width: 80%;
  height: 280px;
  background: linear-gradient(#100241, black);
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid white;
  font-size: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25%;
  max-width: 384px;
}

.phor {
  max-width: 480px;
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: white;
}

.answers {
  max-width: 480px;
  position: absolute;
  top: 82%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.phanswer {
  width: 80%;
  padding: 10px;
  margin: 0 10px 20px 10px;
  text-align: center;
  background: #018cd3;
  border: 1px solid white;
  border-radius: 15px;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  color: black;
}

/* .answer:hover{
  background: mediumblue;
} */

.phanswer.correct {
  -webkit-animation: correct 0.1s ease forwards;
          animation: correct 0.1s ease forwards;
}

@-webkit-keyframes correct {

  /* 2%,
  12%,
  27% {
    background: mediumblue;
  }

  10%,
  25%,
  40% {
    background: linear-gradient(#0e0124, #22074d);
  }

  42%, */
  1%, 100% {
    background: #10d115;
  }
}

@keyframes correct {

  /* 2%,
  12%,
  27% {
    background: mediumblue;
  }

  10%,
  25%,
  40% {
    background: linear-gradient(#0e0124, #22074d);
  }

  42%, */
  1%, 100% {
    background: #10d115;
  }
}

.phanswer.wrong {
  -webkit-animation: wrong 0.1s ease forwards;
          animation: wrong 0.1s ease forwards;
}

@-webkit-keyframes wrong {

  /* 2%,
  12%,
  27% {
    background: mediumblue;
  }

  10%,
  25%,
  40% {
    background: linear-gradient(#0e0124, #22074d);
  }

  42%, */
  1%, 100% {
    background: crimson;
  }
}

@keyframes wrong {

  /* 2%,
  12%,
  27% {
    background: mediumblue;
  }

  10%,
  25%,
  40% {
    background: linear-gradient(#0e0124, #22074d);
  }

  42%, */
  1%, 100% {
    background: crimson;
  }
}

.timesup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}


.start {
  width: 250px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.startInput {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  color: black;
}

.startInput:focus {
  outline: none;
}

.startButton {
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
}

.guessfooter {
  position: absolute;
  top: 70%;
  width: 100%;
  max-width: 480px;
  height: 60px;
  align-items: center;
  justify-content: center;
  /* display: flex;
  flex-direction: row; */
}

.tutorialImage {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
  max-width: 480px;
}

.close {
  position: relative;
  top: -40%;
  right: 20px;
  color: white;
  font-size: 16;
}

.clue {
  padding: 10px;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
}

.phchoose {
  max-width: 480px;
  position: absolute;
  top: 98%;
  font-size: 16px;
  font-weight: 600;
  color: 'white'
}

.clues {
  max-width: 480px;
  position: absolute;
  top: 20%;
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.phoutcome {
  max-width: 480px;
  position: absolute;
  top: 97%;
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.payIcon {
  width: 36px;
  height: 36px;
  object-fit: cover;
  position: absolute;
  right: 25px;
  top: 0px;
  cursor: pointer;
}
.GuessesLeft {
  text-align: center;
  display: inline-flex;
  color: white;
  cursor: default;
  padding: 1rem;
  font-size: 20px;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
}

.GuessContainer {
  width: 100%;
  min-width: 480px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .GuessesLeft {
    width: 100%;
    min-width: 360px;
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin-left: 6%;
  }
  .GuessContainer {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.ClueContainer {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 65px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.SettingText {
  font-size: 24px;
  text-align: center;
  color: yellow;
  font-weight: 600;
  top: -40px;
  width: 100%;
  text-align: center;
  position: absolute;
}

.Clue {
  /* text-transform: uppercase; */
  font-size: 17px;
  font-weight: 400;
  margin-top: 165px;
  width: 90%;
  text-align: center;
  position: absolute;
  color: white;
  z-index: 1;
}

@media only screen and (max-width: 420px) {
  .Clue {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    color: white;
    top: 0px;
  }
  .TipImage {
    left: 0%;
    width: 100%;
  }
}

.WLetterz {
  border-radius: 10%;
  margin: 3px;
  height: 30px;
  width: 25px;
  text-align: center;
  list-style-type: none;
  display: inline-block;
  color: white;
  font-size: small;
  padding-top: 3px;
  /* background-color: red; */

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5),
    /* Exterior Shadow */ inset 0 1px rgba(255, 255, 255, 0.3),
    /* Top light Line */ inset 0 10px rgba(255, 255, 255, 0.2),
    /* Top Light Shadow */ inset 0 10px 20px rgba(255, 255, 255, 0.25),
    /* Sides Light Shadow */ inset 0 -15px 30px rgba(0, 0, 0, 0.1); /* Dark Background */
}

.WLetterz button,
.WLetterz span {
  color: white;
  background: transparent;
  border: none;
}

.WLetterz span {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Letter.disabled {
  box-shadow: none;
}

/* .dash {
  border: none !important;
} */

.space {
  border: none !important;
  display: block;
  background-color: transparent;
  height: 0;
  padding: 0;
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .WLetterz {
    margin: 1vw;
    width: 8vw;
    min-width: 25px;
    font-size: 13px;
  }
}

@media screen and (max-width: 420px) {
  .WLetterz {
    margin: 1vw;
    width: 8vw;
    min-width: 25px;
    font-size: 13px;
  }
}
.WWord .WLetterz button,
.WWord .WLetterz {
  color: white;
  background-color: #db3eb1;
  border: solid #db3eb1 2px;
  border-radius: 4;
  cursor: default;
  box-shadow: none;
  font-weight: 600;
  width: 20px;
  margin-left: -1px;
  margin-bottom: 0px;
}

.WWord .WLetterz button {
  border: none;
}

.WWord .WLetterz.disabled button,
.WWord .WLetterz.dash {
  color: white;
}

.WWord-container {
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: center;
  display: flex;
  padding-right: 3%;
  padding-left: 0%;
}

@media screen and (max-width: 420px) {
  .WWord-container {
    padding-top: 25px;
    padding-bottom: 10px;
    justify-content: center;
    display: flex;
    padding-right: 9%;
    padding-left: 0%;
  }
}
.Topic-container {
  position: relative;
  max-width: 480px;
  height: 100%;
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
}
.Letterz {
  border-radius: 10%;
  margin: 3px;
  height: 30px;
  width: 25px;
  text-align: center;
  list-style-type: none;
  display: inline-block;
  color: white;
  font-size: small;
  /* background-color: red; */

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5),
    /* Exterior Shadow */ inset 0 1px rgba(255, 255, 255, 0.3),
    /* Top light Line */ inset 0 10px rgba(255, 255, 255, 0.2),
    /* Top Light Shadow */ inset 0 10px 20px rgba(255, 255, 255, 0.25),
    /* Sides Light Shadow */ inset 0 -15px 30px rgba(0, 0, 0, 0.1); /* Dark Background */
}

.Letterz button,
.Letterz span {
  color: white;
  background: transparent;
  border: none;
}

.Letterz span {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Letter.disabled {
  box-shadow: none;
}

/* .dash {
  border: none !important;
} */

.space {
  border: none !important;
  display: block;
  background-color: transparent;
  height: 0;
  padding: 0;
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .Letterz {
    margin: 1vw;
    width: 8vw;
    min-width: 25px;
    font-size: 13px;
  }
}

@media screen and (max-width: 420px) {
  .Letterz {
    margin: 1vw;
    width: 8vw;
    min-width: 25px;
    font-size: 13px;
  }
}
.Alphabet .Letterz {
  cursor: pointer;
  font-weight: 600;
  background-color: red;
  padding-top: 4px;  
  font-size: 13px;
}

.Alphabet .Letterz button {
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
}

@media screen and (min-width: 1200px) {
  .Alphabet .Letterz:hover {
    transition: all 0.2s;
    -webkit-transform: rotate(-3deg) scale(1.1);
            transform: rotate(-3deg) scale(1.1);
  }
}

.Alphabet .Letterz.disabled {
  background-color: transparent;
  cursor: not-allowed;
  -webkit-transform: none;
          transform: none;
}

.Alphabet button[disabled] {
  cursor: not-allowed;
}

.Alphabet {
  background-color: orange;
  padding-top: 15px;
  padding-bottom: 20px;
  padding-right: 6%;
  padding-left: 6%;
  justify-content: center;
  align-items: center;
  display: flexbox;
  
}

@media screen and (max-width: 600px) {
  .Alphabet .Letterz {
    padding-right: 28px;
    font-size: 13px;
  }

  .Alphabet {
    padding-right: 0vw;
    padding-left: 1vw;
  }
}

@media screen and (max-width: 420px) {
  .Alphabet .Letterz {
    /* padding-right: 30px; */
    padding-right: 35px;
    padding-top: 4px;  
  }

  .Alphabet {
    padding-right: 0vw;
    padding-left: 1vw;
  }
}
.CategoryContainer {
  border: 5px solid white;
  width: 80%;
  border-radius: 12px;
  height: 150px;
  position: relative;
  left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.CategoryImage {
  width: 100%;
  height: 146px;
  object-fit: stretch;
  position: absolute;
  margin-top: 0px;
  border-radius: 12px;
  z-index: 1;
}

.Category {
  /* text-transform: uppercase; */
  color: white;
  font-size: large;
  text-align: center;
  z-index: 1;
  padding: 5px;
}

@media only screen and (max-width: 420px) {
  .Category {
    font-size: medium;
    font-weight: 400;
    padding: 5px;
  }
  .CategoryImage {
    left: 0%;
    width: 100%;
  }
}

.GuessesLeft {
  text-align: center;
  display: inline-flex;
  color: white;
  cursor: default;
  padding: 1rem;
  font-size: 20px;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
}

.GuessContainer {
  width: 100%;
  min-width: 480px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .GuessesLeft {
    width: 100%;
    min-width: 360px;
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin-left: 6%;
  }
  .GuessContainer {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.Word .Letterz button,
.Word .Letterz {
  color: white;
  background-color: #db3eb1;
  border: solid #db3eb1 2px;
  border-radius: 4;
  cursor: default;
  box-shadow: none;
  font-weight: 600;
  width: 20px;
  margin-left: -1px;
  margin-bottom: 10px;
}

.Word .Letterz button {
  border: none;
}

.Word .Letterz.disabled button,
.Word .Letter.dash {
  color: white;
}

.word-container {
  padding-top: 25px;
  padding-bottom: 20px;
  justify-content: center;
  display: flex;
  padding-right: 3%;
  padding-left: 0%;
}

@media screen and (max-width: 420px) {
  .word-container {
    padding-top: 25px;
    padding-bottom: 20px;
    justify-content: center;
    display: flex;
    padding-right: 9%;
    padding-left: 0%;
  }
}
/* * {
  margin: 0;
  padding: 0;
  font-size: xx-large;
}


body {
  padding: 0.5rem;
  font-size: larger;
}

@media only screen and (max-width: 600px) {
  body {
    padding: 0.1rem;
  }
}

footer .fa {
  color: white;
  float: left;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.footer {
  display: inline-block;
  border-radius: 10px;
  margin-top: 1em;
}

@media only screen and (max-width: 600px) {
  footer {
    margin: auto;
    width: 50%;
  }
}
*/

.footer .fa {
  color: white;
  float: left;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.footer {
  display: inline-block;
  border-radius: 10px;
  margin-top: 1em;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
} 

.topic-main-bg {
  background-color: orange;
  width: 100%;
  max-width: 480px;
  height: 95vh;  
  padding-top: 20px;
  overflow: hidden,
}

.topic-main-image {
  width: 100%;
  height: 95vh; 
  max-width: 480px; 
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.tutorialImage {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
}

.close {
  position: relative;
  top: -40%;
  right: 20px;
  color: white;
  font-size: 16;
}

.ph2answers {
  max-width: 480px;
  position: absolute;
  top: 82%;
  width: 100%;
  left: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ph2answer {
  width: 40%;
  padding: 10px;
  margin: 0 10px 20px 10px;
  text-align: center;
  background: linear-gradient(#0e0124, #22074d);
  border: 1px solid white;
  border-radius: 15px;
  font-weight: 300;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  color: white;
}

.ph2outcome {
  max-width: 480px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 75%;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.ph2choose {
  max-width: 480px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 98%;
  font-size: 16px;
  font-weight: 600;
  color: white
}
.Topic-container {
  position: relative;
  max-width: 480px;
  height: 100%;
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
}

.ph3timer {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* border: 5px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 80px;
  left: 20px;
  font-size: 30px;
  font-weight: 700;
  background-color: white;
}
.tipsLeft {
  text-align: center;
  display: inline-flex;
  color: white;
  cursor: default;
  padding: 1rem;
  font-size: 20px;
  font-weight: 600;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5%;
}

.TipsContainer {
  width: 100%;
  min-width: 480px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .tipsLeft {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin-left: 6%;
    position: absolute;
    right: 0px;
    top: 5%;
  }
  .TipsContainer {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.Letterzph3 {
  border-radius: 10%;
  margin: 3px;
  height: 30px;
  width: 25px;
  text-align: center;
  list-style-type: none;
  display: inline-block;
  color: white;
  font-size: medium;
  /* background-color: red; */

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5),
    /* Exterior Shadow */ inset 0 1px rgba(255, 255, 255, 0.3),
    /* Top light Line */ inset 0 10px rgba(255, 255, 255, 0.2),
    /* Top Light Shadow */ inset 0 10px 20px rgba(255, 255, 255, 0.25),
    /* Sides Light Shadow */ inset 0 -15px 30px rgba(0, 0, 0, 0.1); /* Dark Background */
}

.Letterzph3 button,
.Letterzph3 span {
  color: white;
  background: transparent;
  border: none;
}

.Letterzph3 span {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Letter.disabled {
  box-shadow: none;
}

/* .dash {
  border: none !important;
} */

.space {
  border: none !important;
  display: block;
  background-color: transparent;
  height: 0;
  padding: 0;
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .Letterzph3 {
    margin: 1vw;
    width: 8vw;
    min-width: 25px;
    font-size: 16px;
  }
}

@media screen and (max-width: 420px) {
  .Letterzph3 {
    margin: 1vw;
    width: 8vw;
    min-width: 25px;
    font-size: 16px;
  }
}
.Word .Letterzph3 button,
.Word .Letterzph3 {
  color: white;
  background-color: #334456;
  border: solid white 1px;
  border-radius: 4;
  cursor: default;
  box-shadow: none;
  font-weight: 600;
  width: 20px;
  margin-left: -1px;
  margin-bottom: 10px;
}

.Word .Letterzph3 button {
  border: none;
}

.Word .Letterzph3.disabled button,
.Word .Letter.dash {
  color: white;
}

.word-container {
  padding-top: 25px;
  padding-bottom: 20px;
  justify-content: center;
  display: flex;
  padding-right: 3%;
  padding-left: 0%;
}

@media screen and (max-width: 420px) {
  .word-container {
    padding-top: 25px;
    padding-bottom: 20px;
    justify-content: center;
    display: flex;
    padding-right: 9%;
    padding-left: 0%;
  }
}
/* * {
  margin: 0;
  padding: 0;
  font-size: xx-large;
}


body {
  padding: 0.5rem;
  font-size: larger;
}

@media only screen and (max-width: 600px) {
  body {
    padding: 0.1rem;
  }
}

footer .fa {
  color: white;
  float: left;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.footer {
  display: inline-block;
  border-radius: 10px;
  margin-top: 1em;
}

@media only screen and (max-width: 600px) {
  footer {
    margin: auto;
    width: 50%;
  }
}
*/

.footer .fa {
  color: white;
  float: left;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.footer {
  display: inline-block;
  border-radius: 10px;
  margin-top: 1em;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
} 

.ph3-main-bg {
  background-color: orange;
  width: 100%;
  max-width: 480px;
  height: 95vh;  
  padding-top: 0px;
  overflow: hidden,
}

.topic-main-image {
  width: 100%;
  height: 95vh; 
  max-width: 480px; 
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.tutorialImage {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
}

.close {
  position: relative;
  top: -40%;
  right: 20px;
  color: white;
  font-size: 16;
}

.ph2answers {
  max-width: 480px;
  position: absolute;
  top: 82%;
  width: 100%;
  left: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ph2answer {
  width: 40%;
  padding: 10px;
  margin: 0 10px 20px 10px;
  text-align: center;
  background: linear-gradient(#0e0124, #22074d);
  border: 1px solid white;
  border-radius: 15px;
  font-weight: 300;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  color: white;
}

.ph2outcome {
  max-width: 480px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 75%;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.ph2choose {
  max-width: 480px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 98%;
  font-size: 16px;
  font-weight: 600;
  color: white
}

.ph3Input {
  /* border: 2px solid red; */
  border-radius: 4px;
  width: 75%;
  height: 40px;
  font-size: 20px;
  text-align: center;
}

.ph3InputContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

body { 
  background-color: #292929;
}

#repeatButton {
  -webkit-appearance: none;
          appearance: none;  
  border: none;
  background: url(https://andyhoffman.codes/random-assets/img/slots/repeat.png) transparent 0 0 no-repeat;
  background-size: cover;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 10px;
  right: 20px; 
  cursor: pointer;
  -webkit-animation: 6s linear infinite spin;
          animation: 6s linear infinite spin;
}

h1 {  
  font-size: 150%;
  padding: 0.25em .5em;  
  font-family: 'Cairo', sans-serif;
}

h1 span {
  color: aliceblue;
  border: 1px solid hsla(208, 100%, 97.1%, .2);
  padding: 0.1em .2em;  
}

.icons { 
  display: inline-block;
  width: 128px;
  height: 564px;
  overflow: hidden;
  background: #fff url(https://andyhoffman.codes/random-assets/img/slots/sprite5.png) repeat-y;
  will-change: backgroundPosition;
  transition: 0.3s background-position ease-in-out;
  padding: 0 80px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

.icons:nth-child(2) {
  margin: 0 0px;
}

* { box-sizing: border-box; }

.spinner-container {
  overflow: hidden;
  height: 632px;  
  padding: 2em;
  -webkit-transform: translate(-50%, -50%) scale(.62, .62);
          transform: translate(-50%, -50%) scale(.62, .62);
  position: absolute;
  top: 55%;
  left: 50%;
  display: flex;
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform;
}

.app-wrap.winner-false {
  -webkit-animation: linear 1s spin;
          animation: linear 1s spin;
}

.spinner-container::after {
  position: absolute;
  content: '';
  display: block;
  height: 180px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 30px;
  left: 30px;
  background: rgba(255, 0, 0, .1);  
}

.playbutton2 {
  position: absolute;
	top: 90%;
  height: 40px;
	width: 20%;
  left: 40%;
	border-radius: 8px;
}

.gradient-fade {
  position: absolute;
  top: 32px;
  right: 32px;
  bottom: 32px;
  left: 32px;
  background: linear-gradient(
    to bottom, 
    rgba(64,64,64,1) 0%, 
    rgba(64,64,64,0) 7%, 
    rgba(64,64,64,0) 93%, 
    rgba(64,64,64,1) 100%
  );
}

@media screen and (max-width: 1000px) {
  .spinner-container {
    -webkit-transform: translate(-50%, -50%) scale(.55, .55);
            transform: translate(-50%, -50%) scale(.55, .55);
  }
}

@media screen and (max-width: 500px) {
  .spinner-container {
    -webkit-transform: translate(-50%, -50%) scale(.5, .5);
            transform: translate(-50%, -50%) scale(.5, .5);
  }
}
.slotroot {
	/* max-width: 480px;
	margin: 0 auto;
	width: 100%;
	background-size: 100% auto;
	background-repeat: no-repeat;
	overflow: hidden;
	padding-bottom: 100px; */
	position: relative;
  max-width: 480px;
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: flex-start;
  height: 88vh;
  overscroll-behavior-y: none;
  overflow: hidden;
}
.wheelroot {
	max-width: 480px;
	margin: 0 auto;
	/* background-image: url('images/background.jpg'); */
	width: 100%;
	background-size: 100% auto;
	background-repeat: no-repeat;
	overflow: hidden;
}

.button2 {
	margin-top: 20px;
	height: 40px;
	width: 150px;
	border-radius: 8px;
}

.numContainer {
	margin-top: 20px;
	padding-top: 15px;
  padding-bottom: 20px;
  padding-right: 6%;
  padding-left: 6%;
  justify-content: center;
  align-items: center;
  display: flexbox;
}

.lottobutton {
	cursor: pointer;
  background-color: lightgray;
  text-align: center;
  font-size: 20px;
	padding: 0.4rem 0 2rem 0;
	width: 42px;
  height: 42px;
	color: black;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin: 3px;
}

.lottoStart {
  padding: 1.7rem 0 2rem 0;
	cursor: pointer;
  background-color: lightgray;
  height: 90px;
  width: 90px;
  position: absolute;
  border-radius: 50%;
  background-color: orange;
  border: 6px solid yellow;
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.winContainer {
  height: 80px;
  border: 6px solid white;
  border-radius: 40px;
  width: 320px;
  background-color: darkgray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yourNumberContainer {
  height: 80px;
  border: 6px solid white;
  border-radius: 40px;
  width: 320px;
  background-color: darkgray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yourNumbersContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.yourNum {
  font-size: 24px;
	height: 40px;
  padding: 0.5rem 0 2rem 0;
	text-align: center;
  font-weight: 600;
	color: lightgoldenrodyellow;
  text-shadow: 1px 1px 2px white;
}

.selNum {
  padding: 1rem 0 2rem 0;
  font-size: 18px;
	height: 40px;
  text-align: center;
  font-weight: 600;
	color: black;
  position: absolute;
}

.selNum2 {
  padding: 1.25rem 0 2rem 0;
  font-size: 18px;
	height: 40px;
  text-align: center;
  font-weight: 600;
	color: black;
  position: absolute;
}
.lottoroot {
	/* max-width: 480px;
	margin: 0 auto;
	width: 100%;
	background-size: 100% auto;
	background-repeat: no-repeat;
	overflow: hidden;
	padding-bottom: 100px; */
	position: relative;
  max-width: 480px;
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: flex-start;
  height: 88vh;
  overscroll-behavior-y: none;
  overflow: hidden;
}
.webImageSelector {
  display: flex;
  flex-direction: column;
  background-color: rgba(38, 38, 38, 0.8);
}

.webImageSelector .title {
  color: white;
  text-align: center;
  padding-top: 2px;
  text-transform: uppercase;
}

.webImageSelector .list {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.webImageSelector .listItem {
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.webImageSelector .listItemContent {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.titleTextInput {
  display: flex;
  flex-direction: column;
  background-color: rgba(38, 38, 38, 0.8);

}
.titleTextInput > input {
  padding: 10px;
}
.titleTextInput > .kb {
  width: 375px;
  height: 253px;
}

.friendSelector {
  height: 667px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.friendSelector .header {
  width: 100%;
  height: 50px;
  padding-top: 10px;
  text-transform: uppercase;
  color: black;
  text-align: center;
  font-size: x-large;
  font-weight: bold;
}

.friendSelector .table {
  height: 82%;
  overflow: auto;
}

.friendSelector .row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0px;
  text-decoration: none;
}

.friendSelector .avatar {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.friendSelector .displayName {
  position: relative;
  left: -30px;
}
.friendSelector .checkmark {
  position: relative;
  width: 30px;
  height: 30px;
  align-self: center;
  left: 250px;
  /*right: 20px;*/
}

body {
  background: #000000 !important;
  display: standalone;
}

App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.bg_container {
  max-width: 480px;
  margin: 0 auto 0;
  max-height: 100vh;
  overflow: hidden;
}

.bg_game_video,
.bg_game_iframe {
  width: 100%;
  min-height: 93vh;
  max-height: 93vh;
  overflow: hidden;
}

.bg_game_iframe {
  border: 0;
}

@font-face {
  font-family: canaroMedium;
  src: url(../../static/media/CanaroW00-Medium.02736d3b.ttf);
}
@font-face {
  font-family: canaroBook;
  src: url(../../static/media/CanaroW00-Book.d6950375.ttf);
}
.pages-container {
  width: 350px;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .pages-container {
    width: 100%;
    height: 100%;
  }
}

.button_maiar {
  padding: 0.5rem 2rem!important;
  width: 220px !important;
  color: black !important;
  margin-top: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  height: 40px !important;
  border-radius: 25px !important;
  background-color: white !important;
}

.modal {
  z-index: 999999 !important;
}
.modal button {
  -webkit-appearance: button;
}

iframe[style*="position"] {
  display: none;
}


.wallect-connect-login_cardBody #accessWalletBtn {
  background: #000;
  color: #fff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
}
.wallect-connect-login_cardBody #accessWalletBtn svg {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  margin: 0 10px 0 0 !important;
}
.wallect-connect-login_cardBody #accessWalletBtn svg image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.wallect-connect-login_cardBody #accessWalletBtn svg use {
  -webkit-transform: scale(1);
          transform: scale(1);
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-v {
  background: #eee;
}

.bg_modal {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.75);
          transform: translate(-50%, -50%) scale(0.75);
  overflow: hidden;
  max-width: 375px;
  width: 100%;
  z-index: -9;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  padding: 40px 15px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg_modal_background.modal_bg_show_no_delay {
  z-index: 9999;
  opacity: 1;
  position: fixed;
  left: 50%;
  top: 50%;
  background: #000000;
  overflow: hidden;
  width: 200vw;
  height: 200vh;
  margin: 0 auto;
  max-width: 1000px;
}

.bg_modal_background.modal_bg_show,
.bg_modal.modal_show {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  z-index: 9999;
  opacity: 1;
}
.bg_modal_background {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.75);
          transform: translate(-50%, -50%) scale(0.75);
  background: #0000008f;
  overflow: hidden;
  width: 100%;
  height: calc(100% + 2px);
  margin: 0 auto;
  max-width: 600px;
  z-index: -9;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
