body {
  background: #000000 !important;
  display: standalone;
}

App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.bg_container {
  max-width: 480px;
  margin: 0 auto 0;
  max-height: 100vh;
  overflow: hidden;
}

.bg_game_video,
.bg_game_iframe {
  width: 100%;
  min-height: 93vh;
  max-height: 93vh;
  overflow: hidden;
}

.bg_game_iframe {
  border: 0;
}

@font-face {
  font-family: canaroMedium;
  src: url("./assets/fonts/CanaroW00-Medium.ttf");
}
@font-face {
  font-family: canaroBook;
  src: url("./assets/fonts/CanaroW00-Book.ttf");
}
.pages-container {
  width: 350px;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .pages-container {
    width: 100%;
    height: 100%;
  }
}

.button_maiar {
  padding: 0.5rem 2rem!important;
  width: 220px !important;
  color: black !important;
  margin-top: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  height: 40px !important;
  border-radius: 25px !important;
  background-color: white !important;
}

.modal {
  z-index: 999999 !important;
}
.modal button {
  -webkit-appearance: button;
}

iframe[style*="position"] {
  display: none;
}


.wallect-connect-login_cardBody #accessWalletBtn {
  background: #000;
  color: #fff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
}
.wallect-connect-login_cardBody #accessWalletBtn svg {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  margin: 0 10px 0 0 !important;
}
.wallect-connect-login_cardBody #accessWalletBtn svg image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.wallect-connect-login_cardBody #accessWalletBtn svg use {
  transform: scale(1);
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-v {
  background: #eee;
}

.bg_modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.75);
  overflow: hidden;
  max-width: 375px;
  width: 100%;
  z-index: -9;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  padding: 40px 15px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg_modal_background.modal_bg_show_no_delay {
  z-index: 9999;
  opacity: 1;
  position: fixed;
  left: 50%;
  top: 50%;
  background: #000000;
  overflow: hidden;
  width: 200vw;
  height: 200vh;
  margin: 0 auto;
  max-width: 1000px;
}

.bg_modal_background.modal_bg_show,
.bg_modal.modal_show {
  transform: translate(-50%, -50%) scale(1);
  z-index: 9999;
  opacity: 1;
}
.bg_modal_background {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.75);
  background: #0000008f;
  overflow: hidden;
  width: 100%;
  height: calc(100% + 2px);
  margin: 0 auto;
  max-width: 600px;
  z-index: -9;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}